<template>
  <div class="church-list template-1">
    <header class="page-header container">
      <h1>{{ translations.tcEducationalUnits }}</h1>
    </header>
    <security-banner v-if="!allowPage" :i18n="translations.components"></security-banner>
    <div v-if="allowPage">
      <camp-selector
        @camp_changed="page_load($event)"
        :tooltip="translations.tcCurrentHome"
        :i18n="translations.components"
        :showCamp="false"
      ></camp-selector>
      <data-table-conversations
        :fields="dataTableFields"
        :items="items"
        :hiddenItems="hiddenItems"
        :showExportBtn="false"
        :addItems="addItems"
        :includeAddButton="conversationsICanSee(secured_controls.add_educational_units_button)"
        @addEducationalUnit="addEducationalUnit"
        :searchTerm="`conversationsTerm`"
        :i18n="translations.components"
      ></data-table-conversations>
      <footer class="page-footer"></footer>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters } from 'vuex'
import CampSelect from '@/components/CampSelect.vue'
import DataTableConversations from '@/components/DataTableConversations.vue'
import SecurityBanner from '@/components/SecurityBanner'
import { securityMixin } from '@/mixins/securityMixin'
import { translationMixin } from '@/mixins/translationMixin'

export default {
  name: 'educational-units',
  mixins: [translationMixin, securityMixin],
  data() {
    return {
      addItems: {
        display: true,
        text: 'Add EU',
        action: 'addEducationalUnit'
      },
      dataTableFields: [],
      hiddenItems: {
        display: false
      },
      items: [],
      reportName: 'EducationalUnits',
      secured_controls: {
        add_educational_units_button: 'A8846EE5-0408-4CAB-946A-F32236C8C902'
      },
      translations: {},
      allowPage: true
    }
  },
  async created() {
    try {
      await Promise.all([
        this.setLoadingStatus(true),
        this.getViewTranslations(),
        this.getComponentTranslations('data-table-conversations', 'security-banner', 'camp-select')
      ]).then(results => {
        const componentTranslations = results[2]
        this.$set(this.translations, 'components', componentTranslations)
        this.loadTranslations()
        this.page_load()
      })
    } catch (e) {
      console.error(e)
    } finally {
      //this.setLoadingStatus(false)
    }
  },
  methods: {
    ...mapActions({
      setLoadingStatus: 'menu/setLoadingStatus',
      loadEducationalUnits: 'conversations/loadEducationalUnits'
    }),
    async page_load(evt) {
      if (!evt) {
        // execute only if evt is undefined
        try {
          if (!this.loading) this.setLoadingStatus(true)
          const payload = {
            org_key: this.officerToolbarSelected.country_state,
            lang_key: this.userLanguageKey
          }
          await Promise.all([this.loadEducationalUnits(payload)]).then(() => {
            this.loadDataTableItems()
          })
        } catch (e) {
          console.error('Error in EducationalUnit.vue, page_load()', e)
        } finally {
          this.setLoadingStatus(false)
        }
      }
    },
    addEducationalUnit() {
      this.$router.push('/programs/pw/conversations/educational-unit/add')
    },
    loadTranslations() {
      this.addItems.text = this.translations.tcAddItems
      this.loadDataTableFields()
    },
    loadDataTableItems() {
      if (!this.translations.components || !this.educational_units) return false

      this.items = this.educational_units.map(el => {
        return {
          ind_key: el.ind_key,
          eu: el.name,
          date_certified: el.ixa_start_date && el.ixa_start_date.substring(0, 10),
          note: el.ixa_note,
          history_eu_ind_key: el.ind_key,
          sort_name: el.sort_name
        }
      })
    },
    loadDataTableFields() {
      this.dataTableFields = [
        { key: 'eu', label: `${this.translations.tcName}`, sortable: true },
        {
          key: 'date_certified',
          label: `${this.translations.tcDateCertified}`,
          sortable: true
        },
        { key: 'note', label: `${this.translations.tcComments}`, sortable: true },
        { key: 'history_eu_ind_key', label: ``, sortable: false }
      ]
    }
  },
  computed: {
    ...mapGetters({
      educational_units: 'conversations/educational_units',
      isInMenu: 'menu/isInMenu',
      loading: 'menu/loadingStatus',
      officerToolbarSelected: 'user/officerToolbarSelected',
      prefCulture: 'user/userPreferredCulture',
      userLanguageKey: 'user/userLanguageKey'
    })
  },
  mounted() {},
  components: {
    campSelector: CampSelect,
    securityBanner: SecurityBanner,
    dataTableConversations: DataTableConversations
  }
}
</script>

<style lang="scss" scoped></style>
